import React, {
  createContext,
  ReactNode,
  RefObject,
  useRef,
  useState,
} from "react"
import { connect } from "react-redux"
import {
  connect as connectTwilio,
  createLocalAudioTrack,
  createLocalVideoTrack,
  LocalAudioTrack,
  LocalDataTrack,
  LocalParticipant,
  LocalTrack,
  LocalTrackPublication,
  LocalVideoTrack,
  Participant,
  RemoteAudioTrack,
  RemoteTrack,
  RemoteVideoTrack,
  Room,
} from "twilio-video"

import { ActionWithoutPayload } from "types/redux"

import { DeviceManager } from "../Device"
import { getCall } from "../services/actions"
import {
  DEVICE_ID_DERMATOSCOPE,
  DEVICE_ID_OTOSCOPE,
  DEVICE_ID_STETHOSCOPE,
  DEVICE_ID_STETHOSCOPE_1,
  DEVICE_ID_STETHOSCOPE_2,
  RoomState,
} from "../types/types"
import { createUserLocalTracks, getDeviceInfo } from "../utils"
import styles from "./../Call.module.scss"
import config from "react-global-configuration"
export type ConnectParams = {
  tool_token?: string
  token: string
  roomName: string
}
export enum DEVICE_STATE {
  WAITING = "WAITING",
  USING = "USING",
  CLOSING = "CLOSING",
  UNPLUGGED = "UNPLUGGED",
}
const VIDEO_DEVICES = [DEVICE_ID_DERMATOSCOPE, DEVICE_ID_OTOSCOPE]
declare var webkitSpeechRecognition: any;

export type VideoContextProps = {
  connect: (params: ConnectParams) => void
  disconnect: () => void
  connected: boolean
  state: RoomState
  currentDevice?: { label: string; state: DEVICE_STATE }
  // DEVICE_IN_PROCESS: boolean
  children?: ReactNode
}

// Speech Recognition 
const dataTrack = new LocalDataTrack()
const recognition = new webkitSpeechRecognition();
let isStoppedSpeechRecog = true

const initialProps = {
  // DEVICE_IN_PROCESS: false,
  state: RoomState.ROOM_CONNECTION,
  connected: false,
}

const VideoContext = createContext<VideoContextProps>(
  initialProps as VideoContextProps
)
const VideoProvider = (
  props: React.PropsWithChildren<{ getCurrentCall: () => void }>
) => {
  const [state, setState] = useState(RoomState.ROOM_CONNECTION)
  const [connected, setConnected] = useState(false)
  const [room, setRoom] = useState<Room>()
  const localMedia = useRef<HTMLDivElement>(null)
  const remoteMedia = useRef<HTMLDivElement>(null)
  const toolMedia = useRef<HTMLDivElement>(null)


  

  const [currentTool, setCurrentTool] = useState<{
    label: string
    state: DEVICE_STATE
  }>(undefined)
  const toolRef = useRef<{ label: string; state: DEVICE_STATE }>(null)

  const toolParticipantRef = useRef<LocalParticipant>(null)

  const connectTools = async ({
    roomName,
    tool_token,
  }: {
    roomName: string
    tool_token: string
  }) => {
    console.log("connecting tool to the room...", roomName)
    return connectTwilio(tool_token, {
      name: roomName,
      tracks: [],
    })
      .then((room) => {
        toolParticipantRef.current = room.localParticipant
      })
      .catch((e) => console.error(e, {
        route: "videoContext::connectTools::connectTwilio"
      }))
  }

  const connect = async ({ token, tool_token, roomName }: ConnectParams) => {
    console.log("connecting to the room...", roomName)
    setConnected(true)
    setState(RoomState.ROOM_CONNECTING)

    tool_token && (await connectTools({ tool_token, roomName }))


    return await createUserLocalTracks()
      .then((localTracks: Array<LocalTrack>) => {
          const videoTrack = localTracks.find((x) => x.kind === "video")
        videoTrack && trackSubscribed(videoTrack as LocalVideoTrack, localMedia)
        localTracks.push(dataTrack)
        return connectTwilio(token, {
          name: roomName,
          tracks: localTracks,
        })
      })
      .then((room) => {
        roomEvent(room)
      })
      .catch((e) => {
        console.error(e, {
          route: "videoContext::connect::createUserLocalTracks"
        })
      })
  }

  const disconnect = () => {
    setConnected(false)
    room?.disconnect()
  }

  const roomEvent = (_room: Room) => {
    setRoom(_room)
    setState(RoomState.WAITING_PEER)

    _room.participants.forEach(participantEvent)

    _room.on("participantConnected", participantEvent)

    _room.on("participantDisconnected", (participant) => {
      console.warn(`Participant "${participant.identity}" disconnected`)
      stopSpeechRecognition()
      // this.props.getCurrentCall()
    })

    _room.once("disconnected", (room, error) => {
      console.warn(`[Bye bye] Room disconnected`)
      room.localParticipant.tracks.forEach(
        (publication: LocalTrackPublication) => {
          if (
            publication.track.kind === "audio" ||
            publication.track.kind === "video"
          ) {
            publication.track.stop()
            const attachedElements = publication.track.detach()
            attachedElements.forEach((element) => element.remove())
          }
          if (error) {
            console.log(
              "You were disconnected from the Room:",
              error.code,
              error.message
            )
          }
        }
      )
      disconnect()
      props.getCurrentCall()
    })
  }

  const participantEvent = (participant: Participant) => {
    console.warn(`Participant "${participant.identity}" connected`)

    participant.on(
      "trackSubscribed",
      (track: RemoteVideoTrack | RemoteAudioTrack) =>
        participant.identity.includes("doctor") &&
        trackSubscribed(track, remoteMedia)
    )

    participant.on("trackUnsubscribed", (e) => trackUnsubscribed(e))
  }

  const getDeviceId = (devices: MediaDeviceInfo[], label: string) => {
    return devices.find((device: MediaDeviceInfo) =>
      device.label.includes(label)
    )
  }

  const onDeviceChange = async (): Promise<MediaDeviceInfo[]> => {
    return await new Promise((resolve) => {
      navigator.mediaDevices.addEventListener(
        "devicechange",
        () => resolve(getDeviceInfo()),
        { once: true }
      )
    })
  }

  const setDevice = (currentDevice?: {
    label: string
    state: DEVICE_STATE
  }) => {
    toolRef.current = currentDevice
    setCurrentTool(toolRef.current)
  }

  const awaitingDevice = async (
    label: string
  ): Promise<MediaDeviceInfo | undefined> => {
    const device = await getDeviceInfo().then((devices) => {
      return getDeviceId(devices, label)
    })
    if (device) {
      setDevice({ label, state: DEVICE_STATE.USING })
      return device
    } else {
      // the waiting tool has not yet appear in the mediaDevice list.
      setDevice({ label, state: DEVICE_STATE.WAITING })
      return await onDeviceChange().then(() => awaitingDevice(label))
    }
  }

  const checkDevices = async (label: string): Promise<boolean> => {
    const device = await getDeviceInfo().then((devices) => {
      return getDeviceId(devices, label)
    })
    return !!device
  }

  const trackSubscribed = (
    track: RemoteTrack | LocalTrack,
    div: RefObject<HTMLDivElement>
  ) => {
    console.log("trackSubscribed", track)
    track.kind !== "data" && div.current?.appendChild(track.attach())
    track.kind === "data" && track.on("message", onMessageReceived)
  }
  const onMessageReceived = async (message: string) => {
    console.log("On Message received (Un fois)")
    if (message === "stopDevice") {
      return stopTool()
    }
    if (message === "activate-dermatoscope") {
      joinTool(await awaitingDevice(DEVICE_ID_DERMATOSCOPE))
    } else if (message === "activate-otoscope") {
      joinTool(await awaitingDevice(DEVICE_ID_OTOSCOPE))
    } else if (message === "activate-stethoscope") {
      if (await checkDevices(DEVICE_ID_STETHOSCOPE)) {
        joinTool(await awaitingDevice(DEVICE_ID_STETHOSCOPE))
      } else if (await checkDevices(DEVICE_ID_STETHOSCOPE_1)) {
        joinTool(await awaitingDevice(DEVICE_ID_STETHOSCOPE_1))
      } else if (await checkDevices(DEVICE_ID_STETHOSCOPE_2)) {
        joinTool(await awaitingDevice(DEVICE_ID_STETHOSCOPE_2))
      } else {
        setDevice({
          label: DEVICE_ID_STETHOSCOPE,
          state: DEVICE_STATE.UNPLUGGED,
        })
        console.error("no STETHOSCOPE DETECTED", {
          route: "videoContext::onMessageReceived"
        })
      }
    } 
    else if (message === "start-speech-recognition"){
      startSpeechRecognition()
    }
    else if (message === "stop-speech-recognition"){
      stopSpeechRecognition()
    }
  }


  const stopSpeechRecognition = () => {
    console.log("%cstop-speech-recognition [stop] (Une fois)", "color:green;padding:3px; border:1px solid red;")
    recognition.removeEventListener('result', onRecognitionResult)
    isStoppedSpeechRecog = true
    recognition.stop();
  }
  

  const onRecognitionResult = (e) => {
    const transcript = Array.from(e.results)
      .map((result) => result[0])
      .map((result) => result.transcript)
      .join('');
      console.log("%c[recognotion] result","color:green", transcript,e.results[0].isFinal)
    dataTrack.send(JSON.stringify({
      words: transcript,
      isFinal: e.results[0].isFinal
    }))
  }

  const startSpeechRecognition = () => {
    console.log("%cstart-speech-recognition [start] (Une fois)", "color:green;padding:3px; border:1px solid;")

    recognition.interimResults = true;
    recognition.lang = 'fr-FR';

    recognition.addEventListener('result', onRecognitionResult);

    recognition.addEventListener('end', (condition) => {
      console.log("%c[recognotion] END","color:green")
      if (isStoppedSpeechRecog) {
        recognition.stop();
        console.log("%c[recognotion] END speach","color:green")
      } else {
        console.log("%c[recognotion] CONTINUE speach","color:green")
        try{
          recognition.start();
        } catch(e){
          console.warn(e)
        }
      }
    });

    isStoppedSpeechRecog = false
    recognition.start();
    console.log("%c[recognotion] STARTED]", "color:green")
    
    window.addEventListener("beforeunload", (event) => { recognition.stop() });
  }

  const stopTool = ({ withoutAdvice }: { withoutAdvice?: boolean } = {}) => {
    // Nous sommes sorti du contexte, à cause de l'eventListener sur message
    // Nous utilisons donc une référence à l'objet currentTool
    const toolUsed = toolRef.current

    if (withoutAdvice) setDevice(undefined)
    else
      setDevice({
        label: toolUsed?.label || "",
        state: DEVICE_STATE.CLOSING,
      })

    console.log("[trying to stop : ]", toolParticipantRef?.current)
    console.log("[Automatic recognize shutdown tool]", withoutAdvice)
    if (toolParticipantRef.current) {
      const publications =
        Array.from(toolParticipantRef.current.tracks?.values()) ?? []
      publications.forEach((publication: LocalTrackPublication) => {
        toolParticipantRef.current?.unpublishTrack(publication.track)
        trackUnsubscribed(publication.track)
      })
    }
  }
  const joinTool = async (device?: MediaDeviceInfo) => {
    if (!device) return
    const currentDeviceId = device.deviceId
    const createTrack =
      device.kind === "audioinput"
        ? createLocalAudioTrack
        : createLocalVideoTrack

    await createTrack({
      deviceId: { exact: currentDeviceId },
    })
      .then((track: LocalVideoTrack | LocalAudioTrack) => {
        toolParticipantRef.current?.publishTrack(track)
        trackSubscribed(track as LocalVideoTrack | LocalAudioTrack, toolMedia)
      })
      .catch((e) => {
        console.error(e, {
          route: "videoContext::joinTool::createTrack"
        })
        const label = currentTool?.label ?? "L'outil courant"
        setDevice({
          label,
          state: DEVICE_STATE.UNPLUGGED,
        })
      })

    navigator.mediaDevices.addEventListener(
      "devicechange",
      async () => {
        const devices = await getDeviceInfo()
        // If the deviceChange event return an array where the currentDevice is missing
        // We automatically stop the current tool
        // exemple : if the patient shutdown the device or unplug it
        if (!devices.find((device) => device.deviceId === currentDeviceId))
          stopTool({ withoutAdvice: true })
      },
      { once: true }
    )
  }

  const trackUnsubscribed = (track: RemoteTrack | LocalTrack) => {
    track.kind !== "data" &&
      track.detach().forEach((element: HTMLMediaElement) => element.remove())
  }

  const isVideoDevice = (): boolean =>
    !!currentTool &&
    VIDEO_DEVICES.includes(currentTool.label) &&
    currentTool.state === DEVICE_STATE.USING

  return (
    <VideoContext.Provider
      value={{
        connect,
        disconnect,
        state,
        connected,
      }}
    >
      <div id={styles.CallContainer}>
        <DeviceManager
          device={currentTool}
          onStop={() => {
            setDevice(undefined)
          }}
        />
        <div className={styles.RemoteContainer}>
          <div
            id={styles.RemoteMedia}
            className={`${styles.LoadingBackgroundVideo} ${
              isVideoDevice() ? styles.VideoDeviceIsActive : null
            }`}
            ref={remoteMedia}
          />
          <div className={styles.ChildrenContainer}>{props.children}</div>
          {isVideoDevice() && (
            <div
              id={styles.ToolMedia}
              className={styles.LoadingBackgroundVideo}
              ref={toolMedia}
            />
          )}
        </div>
        <div>
          <div
            id={styles.LocalMedia}
            className={styles.LoadingBackgroundVideo}
            ref={localMedia}
          />
          <div className={styles.HelpSupport}>
            {" "}
            Support : {config.get("support")}{" "}
          </div>
        </div>
      </div>
    </VideoContext.Provider>
  )
}

export { VideoContext }

const mapDispatchToProps = (dispatch: (a: ActionWithoutPayload) => void) => {
  return {
    getCurrentCall: () => dispatch(getCall()),
  }
}
export default connect(null, mapDispatchToProps)(VideoProvider as any) as any
