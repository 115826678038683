import { Form as AntdForm, Checkbox, FormInstance, InputProps, RadioGroupProps } from "antd"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import { Dayjs } from "dayjs"
import React, { useState } from "react"
import { Subject } from "rxjs"

import { Date, INSInput, Input, Nir, Password, OurPhone as Phone, Radiobox } from "components/Input"
import BirthLocation from "components/Input/BirthLocation/Index"
import { Code, CodeInputProps } from "components/Input/Code/Index"
import { DateProps } from "components/Input/Date/Date"
import { NirInputProps } from "components/Input/Nir/Nir"
import NewPassword from "components/Input/Password/NewPassword"
import { OurPhoneProps } from "components/Input/Phone/Phone"
import { GenericModal } from "components/Modal/Modal"
import { Information } from "components/Title/Title"
import { cdn } from "core/cdn"
import { Customer, Gender, Prospect, Relative } from "types/entity"
import { GetInputProps, InputFormProps, InputNames, ItemProps, WarningDefaultForm } from "types/props"

import { getNameOfCurrentInput, labelFromName, rules } from "./utils"
import { useLanguage } from "locales"

export const Item: React.FC<React.PropsWithChildren<ItemProps>> = (
  props
): JSX.Element =>
  props.name ? (
    <AntdForm.Item
      style={{ margin: "auto" }}
      hidden={props.hidden}
      label={!props.labelHidden && labelFromName(props.name)}
      name={getNameOfCurrentInput(props.name)}
      rules={props.rules}
    >
      {props.children}
    </AntdForm.Item>
  ) : (
    <AntdForm.Item>{props.children}</AntdForm.Item>
  )

export const GetInput: React.FC<
  Omit<InputProps, "value"> & {
    value: string | boolean | Dayjs | Gender | Date | undefined
    formRef?: FormInstance
    changeKeyboardValue?: Subject<string>,
    addFilledCondition?: (isFilled: boolean) => void,
    conditionChecked?: boolean
    changeTitle: (newTitle: string | Element) => void,
  }
> = ({ formRef, changeKeyboardValue, ...props }) => {
  const { languages } = useLanguage()
  const female = (formRef && formRef.getFieldValue(InputNames.GENDER)) === Gender.FEMALE

  switch (props.name) {
    case "gender":
      return (
        <Radiobox
          values={[
            {
              imgUrl: cdn("icons/homme_picto.svg"),
              text: languages.male,
              value: Gender.MALE,
            },
            {
              imgUrl: cdn("icons/femme_picto.svg"),
              text: languages.female,
              value: Gender.FEMALE,
            },
          ]}
          formRef={formRef}
          {...(props as unknown as RadioGroupProps)}
        />
      )
    case "birthdate":
      return <Date {...(props as unknown as DateProps)} />
    case InputNames.BIRTH_LOCATION:
      return <BirthLocation formRef={formRef} {...(props as unknown as any)} />
    case "nir":
      return <Nir {...(props as unknown as NirInputProps)} />
    case "password":
    case "old-password":
      return <Password {...(props as unknown as InputProps)} />
    case "new-password":
      return (
        <NewPassword
          {...(props as InputProps)}
          name={getNameOfCurrentInput(props.name)}
        />
      )
    case "phone":
      return (
        <Phone formref={formRef} {...(props as unknown as OurPhoneProps)} />
      )
    case "code":
      return Code({...(props as unknown as CodeInputProps)});
    case "twoFa":
      return Code ({...(props as unknown as CodeInputProps), numberOfChar:6})
    case InputNames.BIRTH_LASTNAME:
      return <INSInput 
                  {...(props as unknown as InputProps)}
                  formRef={formRef} 
                  placeholder={languages.BirthLastnamePlaceholder}
      />
    case InputNames.FIRST_BIRTH_FIRSTNAME:
      return <INSInput 
                  {...(props as unknown as InputProps)}
                  formRef={formRef}
                  placeholder={female?
                    languages.female1stBirthFirstnamePlaceholder 
                    :languages.male1stBirthFirstnamePlaceholder
                  }
      />
    case InputNames.LASTNAME:
        return <Input {...(props as unknown as InputProps)} placeholder={languages.LastnamePlaceholder}/>
    case InputNames.FIRSTNAME:
      return <Input {...(props as unknown as InputProps)}
            placeholder={female? languages.female1stFirstnamePlaceholder : languages.male1stFirstnamePlaceholder}
      />
    default:
      return <Input {...(props as unknown as InputProps)} />
  }
}

export const GetItemForm: React.FC<GetInputProps> = ({
  extra,
  name,
  visible,
  value,
  disabled = false,
  required = false,
  readOnly,
  formRef,
  rules,
  changeKeyboardValue,
  addFilledCondition,
  conditionChecked,
  changeTitle,
}): JSX.Element => {
  const isDevelopment = (window as any).env?.REACT_APP_ENV === "development"
  const { languages } = useLanguage()
  /*
  * Hack to allow keyboard input in development
  */
  const _readOnly =
    typeof readOnly === "boolean" ? readOnly : isDevelopment ? false : true

  const placeholder = ([
    InputNames.BIRTH_LASTNAME, InputNames.FIRST_BIRTH_FIRSTNAME
  ].includes(name as InputNames) && !value && _readOnly) ?
    languages.not_informed_singular : languages[getNameOfCurrentInput(name)]
  return (
    <Item
      extra={extra}
      key={name}
      name={name}
      hidden={!visible}
      required={required}
      labelHidden={true}
      rules={rules}
    >
      <GetInput
        formRef={formRef}
        changeKeyboardValue={changeKeyboardValue}
        id={name}
        name={name}
        value={value}
        disabled={disabled}
        readOnly={_readOnly}
        placeholder={placeholder}
        addFilledCondition={(isFilled) => addFilledCondition(isFilled)}
        changeTitle={(newTitle) => changeTitle(newTitle)}
        conditionChecked={conditionChecked}
      />
    </Item>
  )
}

export const HiddenInputSwitch: React.FC<{
  inputs: InputFormProps[]
  prospect?: Partial<Customer | Prospect | Relative | unknown>
}> = (props): JSX.Element => {
  const inputsNode = props.inputs.map((i: InputFormProps, key) => {
    return (
      <div key={key}>
        <GetItemForm
          name={i.name}
          required={i?.required || false}
          visible={false}
          prospect={props.prospect}
          rules={i.rules}
        />
      </div>
    )
  })
  return <>{inputsNode}</>
}
export const Default: React.FC<{
  checked: boolean
  onClick: (e?: string) => void
  default: { warning?: WarningDefaultForm; value?: string; label: string }
}> = (props): JSX.Element => {
  const [modalVisible, setModalVisible] = useState(false)
  return (
    <>
      <Checkbox
        checked={props.checked}
        onChange={(event: CheckboxChangeEvent) => {
          if (event.target.checked && props.default.warning)
            setModalVisible(true)
          else if (event.target.checked) props.onClick(props.default?.value)
          else props.onClick(undefined)
        }}
      >
        <Information text={props.default.label} style={{ margin: "0px" }} />
      </Checkbox>
      <GenericModal
        visible={modalVisible}
        {...props.default?.warning}
        onAccept={() => {
          props.onClick(props.default.value)
          setModalVisible(false)
        }}
        onCancel={() => {
          props.onClick(undefined)
          setModalVisible(false)
        }}
      />
    </>
  )
}
