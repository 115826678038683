import { createLocalTracks, CreateLocalTracksOptions, LocalDataTrack } from "twilio-video"
import React from "react"
import { cdn } from "core/cdn"

import styles from "../Call.module.scss"
import { DEVICE_STATE } from "../contexts/videoContext"
import {
  DEVICE_ID_DERMATOSCOPE,
  DEVICE_ID_OTOSCOPE,
  DEVICE_ID_STETHOSCOPE,
  DEVICE_ID_STETHOSCOPE_1,
  DEVICE_ID_STETHOSCOPE_2,
} from "../types/types"
import { useLanguage } from "locales"

export const isMobile = (() => {
  if (
    typeof navigator === "undefined" ||
    typeof navigator.userAgent !== "string"
  ) {
    return false
  }
  return /Mobile/.test(navigator.userAgent)
})()
export async function getDeviceInfo(): Promise<MediaDeviceInfo[]> {
  return await navigator.mediaDevices.enumerateDevices()
}

// This function will return 'true' when the specified permission has been denied by the user.
// If the API doesn't exist, or the query function returns an error, 'false' will be returned.
export async function isPermissionDenied(
  name: PermissionName
): Promise<boolean> {
  if (navigator.permissions) {
    try {
      const result = await navigator.permissions.query({ name })
      return result.state === "denied"
    } catch (e) {
      console.error(e, {route: "Calls::utils::isPermissionDenied"})
      return false
    }
  } else {
    return false
  }
}
export async function getMediasVideoChat() {
  return navigator.mediaDevices.enumerateDevices().then((devices) => {
    return devices.filter(
      (device) =>
        !device.label.includes(DEVICE_ID_DERMATOSCOPE) &&
        !device.label.includes(DEVICE_ID_OTOSCOPE) &&
        !device.label.includes(DEVICE_ID_STETHOSCOPE) &&
        !device.label.includes(DEVICE_ID_STETHOSCOPE_1) &&
        !device.label.includes(DEVICE_ID_STETHOSCOPE_2)
    )
  })
}

const MicroDeviceLabel = "Communications";

export async function areConnectedDeviceOff() {
  return navigator.mediaDevices.enumerateDevices().then((devices) => {
    return devices.filter(
      (device) =>
        !device.label.includes(MicroDeviceLabel) && (
        device.label.includes(DEVICE_ID_DERMATOSCOPE) ||
        device.label.includes(DEVICE_ID_OTOSCOPE))
    ).length == 0
  }).catch((e) => {
    console.error(e);
    return true;
  })

}

export async function createUserLocalTracks(
  options?: CreateLocalTracksOptions
) {
  const mediasVideoChat = await getMediasVideoChat()

  const audioInput =
    mediasVideoChat.find(
      (device) => device.kind === "audioinput" && device.deviceId === "default"
    ) || mediasVideoChat.find((device) => device.kind === "audioinput")

  const videoInput =
    mediasVideoChat.find(
      (device) => device.kind === "videoinput" && device.deviceId === "default"
    ) || mediasVideoChat.find((device) => device.kind === "videoinput")

  console.log("[video]", videoInput?.label)
  console.log("[audio]", audioInput?.label)
  
  return createLocalTracks({
    ...options,
    audio: audioInput ? { deviceId: audioInput.deviceId } : true,
    video: videoInput ? { deviceId: videoInput.deviceId } : true,
  })
}

export const DeviceInfo: React.FC<{
  device?: { label: string; state: DEVICE_STATE }
  onClick: () => void
}> = ({ device, onClick }) => {
  const { languages } = useLanguage()
  const stateText = (state: DEVICE_STATE, label: string) => {
    switch (state) {
      case DEVICE_STATE.WAITING:
        if ([DEVICE_ID_STETHOSCOPE, DEVICE_ID_STETHOSCOPE_1, DEVICE_ID_STETHOSCOPE_2].includes(label))
          return (
            labelText(label) +
            " n'est pas branché, il ne peut pas être utilisé."
          )
        return "Veuillez allumer " + labelText(label)
      case DEVICE_STATE.USING:
        return "Utilisation en cours : " + labelText(label)
      case DEVICE_STATE.CLOSING:
        if ([DEVICE_ID_STETHOSCOPE, DEVICE_ID_STETHOSCOPE_1, DEVICE_ID_STETHOSCOPE_2].includes(label))
          return "Veuillez reposer " + labelText(label)
        return "Veuillez éteindre " + labelText(label)
      default:
        return "undefined Device status "
    }
  }

  const labelText = (label: string) => {
    switch (label) {
      case DEVICE_ID_OTOSCOPE:
        return "l'otoscope (outil A)"
      case DEVICE_ID_STETHOSCOPE:
      case DEVICE_ID_STETHOSCOPE_1:
      case DEVICE_ID_STETHOSCOPE_2:
        return "le stéthoscope (outil B)"
      case DEVICE_ID_DERMATOSCOPE:
        return "le dermatoscope (outil D)"
      default:
        return "l'outil en cours"
    }
  }

  const labelImage = (label: string) => {
    switch (label) {
      case DEVICE_ID_OTOSCOPE:
        return cdn("images/devices/otoscope.svg")
      case DEVICE_ID_STETHOSCOPE:
      case DEVICE_ID_STETHOSCOPE_1:
      case DEVICE_ID_STETHOSCOPE_2:
        return cdn("images/devices/stethoscope.svg")
      case DEVICE_ID_DERMATOSCOPE:
        return cdn("images/devices/dermatoscope.svg")
      default:
        return ""
    }
  }

  return (
    <div>
      {device ? (
        <div
          className={`${styles.DeviceInfo} ${
            device ? styles.Opened : styles.Closed
          }`}
          onClick={() => {
            device.state === DEVICE_STATE.CLOSING && onClick()
          }}
        >
          {stateText(device.state, device.label)}
          {device.state === DEVICE_STATE.CLOSING ? (
            <div>
              <p>{languages.clickMeToVanish}</p>
              <img height="50px" src={cdn("/images/smiley.svg")} alt="" />
            </div>
          ) : null}
          {device.state === DEVICE_STATE.WAITING && (
            <img
              className={styles.DeviceImage}
              src={labelImage(device.label)}
              alt={labelText(device.label)}
            />
          )}
        </div>
      ) : null}
    </div>
  )
}
