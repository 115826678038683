import { createRoot } from 'react-dom/client';
import * as serviceWorker from "./serviceWorker"
import Root from "core/routing"
import createSagaMiddleware from "redux-saga"
import { createStore, applyMiddleware, compose } from "redux"
import { createReduxHistoryContext } from "redux-first-history";
import { HistoryRouter } from "redux-first-history/rr6";
import { Provider } from "react-redux"
import createRootReducers from "core/reducers"
import IndexSagas from "core/sagas"
import { composeWithDevTools } from "redux-devtools-extension"
import { createBrowserHistory } from "history"
import config from "react-global-configuration"
import configuration from "./config"
import "./styles/index.scss"
import { withHeaderAndFooter } from "./hocs"

import { openNotification } from "components/Message/Message"
import LanguageProvider from 'locales/languageProvider';
import BirthLocationProvider from 'components/Form/birthLocationProvider';

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory() })

config.set(configuration)

const sagaMiddleware = createSagaMiddleware({
  onError: (error) => {
    console.error(error, {
      route: "saga::sagaMiddleWare"
    })
    openNotification({
      type: "error",
      text: "Une erreur inconnue est survenue",
    })
  },
})

const RootPage = withHeaderAndFooter(Root) as any
const store = createStore(
  createRootReducers(routerReducer),
  compose(
    process.env.NODE_ENV !== "production"
      ? composeWithDevTools(
          applyMiddleware(sagaMiddleware),
          applyMiddleware(routerMiddleware)
        )
      :     applyMiddleware(sagaMiddleware), applyMiddleware(routerMiddleware)
  )
)

sagaMiddleware.run(IndexSagas)

// import { ThemeProvider } from "styled-components"
// const Theme = ({ children }: any) => (
//   <ThemeProvider theme={config.get("theme")}>{children}</ThemeProvider>
// )

export const history = createReduxHistory(store)

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <HistoryRouter history={history}>
      <LanguageProvider>
      <BirthLocationProvider>
      <RootPage />
      </BirthLocationProvider>
      </LanguageProvider>
    </HistoryRouter>
  </Provider>
)

serviceWorker.unregister()
