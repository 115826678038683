export enum RoomState {
  ERROR = -1,
  ROOM_CONNECTION = 0,
  WAITING_PEER = 1,
  LINKING_PEER = 2,
  LOADING_STREAM = 3,
  CONNECTION_LOST = 4,
  RECONNECTION = 5,
  PEER_CONNECTION_LOST = 6,
  PEER_RECONNECTION = 7,
  ROOM_CONNECTING = 8,
}

export const DEVICE_ID_DERMATOSCOPE = "Digital Microscope "
export const DEVICE_ID_STETHOSCOPE = "0d8c:0134"
export const DEVICE_ID_STETHOSCOPE_1 = "PnP"
export const DEVICE_ID_STETHOSCOPE_2 = "08dc:0134"
export const DEVICE_ID_OTOSCOPE = "Digital Microscope"