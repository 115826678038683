import React, { useMemo, useState } from "react";
import { BirthPlace } from "types/entity";

export type BirthPlaceValue = BirthPlace.FRANCE | BirthPlace.FOREIGN | BirthPlace.UNKNOWN

export type BirthLocationContextProps = {
  birthdate: string;
  locationCode : BirthPlaceValue;
  inseeCode : string;
  locationName: string;
  updateBirthdate: (birthdate: string)=>void;
  updateInseeCode: (inseeCode: string)=>void;
  updateLocationCode: (locationCode: BirthPlaceValue)=>void;
  updateLocationName: (locationName: string)=>void;
  cleanBirthLocationData: () =>void;
}


export const BirthLocationContext = React.createContext<BirthLocationContextProps | null>(null);

export function useBirthLocation(){
    const context = React.useContext(BirthLocationContext);
    if (!context) {
      throw new Error('useBirthLocation must be used within a BirthLocationProvider');
    }
    return context;
};

export default function BirthLocationProvider({ children }){
  const [birthLocation, setBirthLocation] = useState({
    birthdate: "",
    locationCode : BirthPlace.FRANCE,
    inseeCode : "",
    locationName: ""
  })

  const cleanBirthLocationData = () => setBirthLocation({
    birthdate: "",
    locationCode : BirthPlace.FRANCE,
    inseeCode : "",
    locationName: ""
  })
  const updateBirthdate = (birthdate: string) => setBirthLocation(value => ({...value, birthdate }))

  const updateInseeCode = (inseeCode: string) => setBirthLocation(value => ({...value, inseeCode }))

  const updateLocationCode = (locationCode: BirthPlaceValue) => setBirthLocation(value => ({
        ...value,
        locationCode
  }))

  const updateLocationName = (locationName: string) => setBirthLocation(value => ({
        ...value,
        locationName
  }))

  return (
    <BirthLocationContext.Provider value={{
      birthdate: birthLocation.birthdate,
      inseeCode : birthLocation.inseeCode,
      locationName : birthLocation.locationName,
      locationCode : birthLocation.locationCode,
      updateBirthdate,
      updateInseeCode,
      updateLocationCode,
      updateLocationName,
      cleanBirthLocationData,
    }}>
      {children}
    </BirthLocationContext.Provider>
  );
};