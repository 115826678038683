import { useEffect, useState } from "react";
import { Carousel } from "components/Carousel/Index"

import { INSERT_RELATIVE_PAGE, SET_RELATIVE_PAGE }  from "core/constants";
import { Input } from "components/Input"
import { BirthPlace } from "types/entity";

import config from "react-global-configuration";
import FatButton from "components/Button/FatButton/FatButton";
import searchCityFullNameWithInseeCode from "api/SearchBirthLocationName"
import searchLocation from "api/SearchLocation"
import { useLanguage } from "locales";
import { useBirthLocation } from "components/Form/birthLocationProvider";
import { getCurrentLanguage, languages } from "locales/languages";

const fr = getCurrentLanguage()==="fr"

export const getTitleByCountry = (pathname, country) => {
  const isRelative = [INSERT_RELATIVE_PAGE, SET_RELATIVE_PAGE].includes(pathname);
  switch(country) {
    case BirthPlace.FRANCE:
      return isRelative ? languages.inputYourRelativeBirthCity :  languages.inputYourBirthCity ;
    case BirthPlace.FOREIGN:
      return isRelative ? languages.inputYourRelativeBirthCountry : languages.inputYourBirthCountry;
    case BirthPlace.UNKNOWN:
      return languages.unknownBirthPlace;
    default:
      return languages.unknownBirthPlace;
  }
}

const BirthLocation = (props: any) => {
    const { languages } = useLanguage()
    const { inseeCode, locationName, birthdate, locationCode, updateLocationCode, updateLocationName, updateInseeCode } = useBirthLocation()

    const [locationList, setLocationList] = useState(
      [
        {
          fullname_with_suffix : locationName || fr? "Inconnu" : "Unknown",
          insee_code: inseeCode || "99999"
        }
      ]
    );

    useEffect(() => {
      // Si le code INSEE est pré-remplis depuis la carte vitale
      // Nous allons chercher la ville associée
      // Et injecter sa valeur dans l'input 
      // if(props.formRef){
      //   const inseeCode = props.formRef.getFieldValue(InputNames.INSEE_CODE);
      // if(inseeCode === UNKNOWN_INSEE_CODE) {
      //   props.formRef.setFieldValue(InputNames.BIRTH_LOCATION, languages.unknown);
      //   // avoid Unknown answer
      // } else 
      if(inseeCode && birthdate && !locationName) {
        try {
          searchCityFullNameWithInseeCode(inseeCode, birthdate)
          .then((res: any) => {
            if(res && res.status === "ok") {
              if(inseeCode.slice(0,2) === "99") {
                updateLocationCode(BirthPlace.FOREIGN)
                props.changeTitle(getTitleByCountry(window.location.pathname, BirthPlace.FOREIGN));
              }
              updateLocationName(res?.locations?.[0]?.fullname_with_suffix)
              updateInseeCode(inseeCode)
              props.onChange(res?.locations?.[0]?.fullname_with_suffix);
            } else {
              updateLocationName(languages.unknown)
              props.onChange(languages.unknown);
            }
            setLocationList([]);
          })
          return;
        } catch(error) {
          console.error(error, {
            route: config.get("locations")
          });
          updateLocationName(languages.unknown)
        }
      }
    }, []);

    useEffect(() => {

      if(/[(][0-9]{1,}[)]/.test(props.value)) {
        return;
      }
      if(props.value){
        let launchRequest = setTimeout(() => {
          searchLocation(props.value, birthdate, locationCode)
          .then((cityFound: any) => setLocationList(cityFound))
      }, 500);
      return () => clearTimeout(launchRequest);
      }

    }, [props.value, birthdate, locationCode]);
    
    return (
      props.disabled?
      <Input  {...(props as unknown as any)} value={!props.value? languages.not_informed_singular : props.value}/>
      :
      <div>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
            <div><input style={{width: "1250px"}} type="text" name="birth_location" value={props.value} readOnly/> </div>
          </div>
          <div style={{height: "90px"}}>
            {locationList.length > 0 && (
              <Carousel
                itemsPerPage={3}
                items={locationList.map((location) => (
                  <FatButton style={{height: "90px", fontSize: "25px", cursor : "pointer"}}
                    onClick={() =>{
                      updateInseeCode(location.insee_code)
                      updateLocationName(location.fullname_with_suffix)
                      props.onChange(location.fullname_with_suffix);
                      setLocationList([])
                    }
                    }><div style={{display: "flex", flexDirection: "column", alignContent: "space-evenly", textAlign: "center"}}> <span
                    style={{
                      maxHeight: "90px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      maxWidth: "250px",
                    }}>{location.fullname_with_suffix}</span> </div>
                  </FatButton>
                ))}
                infinite={false}
                style={{height: "auto", width: "auto"}}
              />
            )}
          </div>
    </div>
    )
}


export default BirthLocation;