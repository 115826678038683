import { Form as AntdForm, FormProps, Checkbox } from "antd"
import { useForm } from "antd/lib/form/Form"
import dayjs from "dayjs"
import { find } from "lodash"
import React, { useEffect, useState } from "react"
import { Subject } from "rxjs"

import { Button } from "components/Button"
import { Buttons } from "components/Card/Card"
import Message from "components/Message/Message"
import { NirBelongsToChildModal } from "components/Modal/NirBelongsToChildModal"
import PreviousButton from "components/Previous/PreviousButton"
import { Steps } from "components/Steps"
import { Information, Title } from "components/Title/Title"
import { Keyboard } from "hocs/withKeyboard"
import { nirAPIValidator } from "lib/form"
import { getBirthdateFromNir } from "lib/sesam"
import { capitalize, isValidNir } from "lib/utils"
import { FormPageProps, InputFormProps, InputNames } from "types/props"
import { Message as MessageInterface } from "types/redux"

import { Default, GetItemForm, HiddenInputSwitch } from "./Input"
import { getNameOfCurrentInput, labelFromName, rules } from "./utils"
import { cdn } from "core/cdn";
import { GenericModal } from "components/Modal/Modal";
import FatButton from "components/Button/FatButton/FatButton";
import { getTitleByCountry } from "components/Input/BirthLocation/Index"
import { BirthPlace } from "types/entity";
import { INSERT_RELATIVE_PAGE, SET_RELATIVE_PAGE } from "core/constants";
import { useLanguage } from "locales"
import { BirthPlaceValue, useBirthLocation } from "./birthLocationProvider"


export const Form: React.FC<React.PropsWithChildren<FormProps>> = ({
  children,
  ...rest
}): JSX.Element => {
  return (
    <AntdForm {...rest} layout="vertical">
      {children}
    </AntdForm>
  )
}

export const FormPage = ({
  inputs,
  prospect,
  loading,
  acceptText,
  cancelText,
  hidePrevious,
  readOnly,
  ...formProps
}: FormPageProps): JSX.Element => {
  const { languages } = useLanguage()
  const { locationCode, inseeCode, updateLocationCode, updateBirthdate, updateInseeCode } = useBirthLocation()
  const [formRef] = useForm()
  const keyboardEvent = new Subject<string>()
  // we initialize current with the first index that is not hidden.
  const [current, setCurrent] = useState(inputs.findIndex(value => value?.type !== "hidden"))
  const [modalNirBelongsToChild, setModalNirBelongsToChild] = useState(false)
  const [disableNextButton, setDisableNextButton] = useState<boolean>(false)
  const [loadingNextButton, setLoadingNextButton] = useState<boolean>(false)
  const [formMessage, setFormMessage] = useState<MessageInterface | undefined>(
    undefined
  )

  const [conditionState, setConditionState] = useState({});

  const [myInputs, setMyInputs] = useState(inputs);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const getCurrentLabel = (currentInput: InputFormProps) => {
    return currentInput && currentInput.keepLabelFormat
      ? currentInput.label
      : typeof currentInput.label === "string"
        ? capitalize(currentInput.label)
        : currentInput.label ||
        capitalize(`${languages.inputYour} ${labelFromName(currentInput.name)}`)
  }

  // Get the current input to display
  const getCurrent = (current: number): InputFormProps => {
    return find(
      myInputs,
      (input, key: number) => key === current
    ) as InputFormProps
  }
  const currentInput: InputFormProps = getCurrent(current)

  const [defaultTitle, setDefaultTitle] = useState(getCurrentLabel(currentInput));

  useEffect(() => {
    if(inseeCode){
      checkInseeCodeIsSet(inseeCode)
      formRef.setFieldValue(InputNames.INSEE_CODE, inseeCode)
    }
  }, [inseeCode])

  useEffect(() => {
    setDefaultTitle(getCurrentLabel(currentInput));
  }, [currentInput.name])

  let isConditionFilled = false;

  const addFilledCondition = (check: boolean) => {
    isConditionFilled = check;
    setConditionState({ ...conditionState, [currentInput.name]: isConditionFilled });
    if (isConditionFilled) {
      // check if condition is not allready filled
      if (myInputs.indexOf((i) => i.name === currentInput.condition?.filled?.name) === -1) {
        myInputs.splice(myInputs.findIndex((i) => i.name === currentInput.name) + 1, 0, currentInput.condition.filled)
        setMyInputs(myInputs);
      }
    } else {
      myInputs.splice(myInputs.findIndex((i) => i.name === currentInput.condition?.filled?.name), 1)
      setMyInputs(myInputs)
    }
  }

  useEffect(() => {
    if (formProps.formRef) {
      formProps.formRef.on("reset", (inputs: string[] | undefined) => {
        setTimeout(() => {
          formRef.resetFields(inputs)
          keyboardEvent.next("reset")
          checkChangedValue()
          setCurrent(0)
        }, 500)
      })
      formProps.formRef.on("set", (inputs: any) => {
        formRef.setFieldsValue(inputs)
        checkChangedValue()
        setCurrent(0)
      })
    }
  }, [])

  const [currentValue, setCurrentValue] = useState(
    prospect ? (prospect as any)[currentInput.name] ?? undefined : undefined
  )

  useEffect(() => {
    const freshValue =
      formRef.getFieldValue(getNameOfCurrentInput(currentInput.name)) ||
      undefined
    setCurrentValue(freshValue)

    if (currentInput.name === "nir") {
      if (freshValue) validateNirManager(freshValue)
      else setDisableNextButton(true)
    } else {
      if ([
        InputNames.FIRST_BIRTH_FIRSTNAME,
        InputNames.BIRTH_LASTNAME,
        InputNames.FIRSTNAME,
        InputNames.LASTNAME,
        InputNames.BIRTH_LOCATION
      ].includes(currentInput.name as InputNames)) {
        setDisableNextButton(true);
        if (currentInput.name === InputNames.BIRTH_LOCATION) {
          const inseeCodeIsSet = formRef.getFieldValue(InputNames.INSEE_CODE)
          if(freshValue && !/^[ \-'']*$/g.test(freshValue) && inseeCodeIsSet){
            setDisableNextButton(false);
          }
        } 
        else {
          if (!/^[ \-'']*$/g.test(freshValue)) {
            setDisableNextButton(false);
          }
        }
      } else {
        setDisableNextButton(false);
      }
    }

    // Focus the currentInput
    // -> Has been commented because there is a conflict with react-mask-input
    // document.getElementById(getNameOfCurrentInput(currentInput.name))?.focus()
  }, [current, inseeCode])

  const handlePrev = () => {
    let prev = current - 1
    let criteria = myInputs[prev]
    while (prev >= 0 && criteria?.type === "hidden") {
      prev = prev - 1
      criteria = myInputs[prev]
    }
    if (prev < 0) {
      formRef.resetFields()
      formProps.onCancel()
      return
    }
    else {
      setCurrent(prev)
    }
  }

  const next = () => {
    if(currentInput.name === InputNames.BIRTHDATE){
      const birthdate = formRef.getFieldValue(getNameOfCurrentInput(currentInput.name))
      updateBirthdate(birthdate)
    }
    const next = current + 1
    const index = myInputs.slice(next).findIndex(value => value?.type !== "hidden")
    if (index === -1) {
      formRef.submit()
    }
    else {
      setCurrent(next + index)
    }
  }

  const handleNext = () => {
    const inputsToValidate = [currentInput.name]
    formRef.getFieldsValue()
    formRef.validateFields(inputsToValidate)
      .then(next)
      .catch(error => console.error('Validation failed:', error))
  }

  const validateNirManager = (value: string) => {
    const valid = () => {
      setDisableNextButton(false)
      setFormMessage(undefined)
    }

    const invalid = (e: string = languages.nirInvalid) => {
      setDisableNextButton(true)
      setFormMessage({ type: "error", text: e })
    }

    if (isValidNir(value)) {
      setLoadingNextButton(true)
      nirAPIValidator(value)
        .then(valid)
        .then(() => {
          // Check the age
          const birthdate = getBirthdateFromNir(value)
          const age = dayjs().diff(birthdate, "year")
          if (age < 16) setModalNirBelongsToChild(true)
        })
        .catch(invalid)
        .finally(() => setLoadingNextButton(false))
    } else {
      invalid()
      return
    }
  }

  const checkInseeCodeIsSet = (value: string) => {
    if(value && /^\d{5}$/.test(value)){
      setDisableNextButton(false)
      return
    }
    setDisableNextButton(true);
  }

  // Form trigged by the input change
  const checkChangedValue = (e?: any) => {
    const freshValue = formRef.getFieldValue(currentInput.name) || undefined
    if ([
      InputNames.FIRST_BIRTH_FIRSTNAME,
      InputNames.BIRTH_LASTNAME,
      InputNames.FIRSTNAME,
      InputNames.LASTNAME
    ].includes(currentInput.name as InputNames)) {
      if (/^[ \-'']*$/g.test(freshValue) || !freshValue) {
        // if freshValue is empty string or spaces or dashes the button is still desabled
        setDisableNextButton(true)
      } else {
        setDisableNextButton(false)
      }
    }
    if (currentInput.name === "nir") {
      // Gestion nir s'il est rempli
      if (freshValue && freshValue.length === 15) validateNirManager(freshValue)
      else setDisableNextButton(true)
    }
    setCurrentValue(freshValue)
    if (freshValue && currentInput.autoSubmit) handleNext()
  }

  const isFirstInput = !myInputs[current - 1]

  return (
    <div style={{ width: currentInput.name === InputNames.BIRTH_LOCATION ? "1600px" : "1200px", margin: "auto" }}>
      <Message message={formMessage} />
      <Title text={defaultTitle} size="medium" />
      <Form
        validateTrigger="onSubmit"
        form={formRef}
        onValuesChange={checkChangedValue}
        {...(formProps as FormProps)}
        onFinish={(values) => {
          const birthdateValue = values.birthdate && dayjs(values.birthdate, "DDMMYYYY")
          const birthdate = birthdateValue ? {
            birthdate: birthdateValue
          } : null
          formProps.onFinish({
            ...values,
            ...birthdate,
          })
        }}
        initialValues={{
          ...prospect,
        }}
      >
        {/*  Here is our current input of the form. We provide the keyboard to it */}
        <Keyboard
          readOnly={readOnly}
          event={keyboardEvent}
          form={formRef}
          value={currentValue}
          onChange={(value: string) => {
            checkChangedValue()
          }}
          options={{
            type: getNameOfCurrentInput(currentInput.name),
            inputName: getNameOfCurrentInput(currentInput.name),
          }}
        >
          <GetItemForm
            formRef={formRef}
            extra={currentInput.extra}
            rules={[...rules(currentInput.name, currentInput.required ?? true, currentInput.rules)]}
            name={currentInput.name}
            required={currentInput?.required || false}
            visible={true}
            readOnly={currentInput.readOnly}
            addFilledCondition={(isChecked: boolean) => addFilledCondition(isChecked)}
            conditionChecked={conditionState[currentInput.name]}
            changeTitle={(newTitle: string) => setDefaultTitle(newTitle)}
          />
        </Keyboard>

        {/* Hide all other inputs to maintain them in the form */}
        <HiddenInputSwitch
          inputs={myInputs.filter((input) => input.name !== currentInput.name)}
        />
        <Buttons>
          {
            currentInput.condition && currentInput.condition.type === "checkbox" && (<div>
              <Checkbox checked={conditionState[currentInput.name]} onClick={() => { addFilledCondition(!conditionState[currentInput.name]) }}>
                <Information text={currentInput?.condition?.label} style={{ margin: "0px" }} />
              </Checkbox>
            </div>
            )
          }
          {
            currentInput.name === InputNames.BIRTH_LOCATION && (
              <div>
                <Checkbox
                  checked={locationCode !== BirthPlace.FRANCE}
                  onClick={() => { setIsModalOpen(true) }}>
                  <Information text={[INSERT_RELATIVE_PAGE, SET_RELATIVE_PAGE].includes(window.location.pathname) ? languages.relativeNotBornInFrance : languages.notBornInFrance} style={{ margin: "0px" }} />
                </Checkbox>
                <GenericModal title={languages.chooseYourNationality} visible={isModalOpen} closable onClose={() => setIsModalOpen(false)}>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly" }}>
                    {
                      locationCode !== BirthPlace.FRANCE ?
                        <FatButton 
                            img={cdn("images/france_icon.svg")}
                            onClick={() => { 
                              setIsModalOpen(false);
                              setCurrentValue("");
                              formRef.setFieldValue(InputNames.BIRTH_LOCATION, "");
                              updateLocationCode(BirthPlace.FRANCE);
                              setDefaultTitle(getTitleByCountry(window.location.pathname, BirthPlace.FRANCE)) }}
                              >{languages.french}
                        </FatButton> :
                        (
                          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly", gap: "40px" }}>
                              <FatButton 
                                img={cdn("images/foreign_icon.svg")} 
                                onClick={() => { 
                                  setIsModalOpen(false);
                                  setCurrentValue("");
                                  formRef.setFieldValue(InputNames.BIRTH_LOCATION, "");
                                  updateLocationCode(BirthPlace.FOREIGN);
                                  setDefaultTitle(getTitleByCountry(window.location.pathname, BirthPlace.FOREIGN)) }}>
                                    {languages.foreign}
                              </FatButton>
                              <FatButton 
                                  img={cdn("images/unknown_icon.svg")}
                                  onClick={() => {
                                    setIsModalOpen(false);
                                    setCurrentValue(languages.unknown);
                                    formRef.setFieldValue(InputNames.BIRTH_LOCATION, languages.unknown);
                                    updateLocationCode(BirthPlace.UNKNOWN);
                                    updateInseeCode('99999');
                                    setDisableNextButton(false);
                                    setDefaultTitle(getTitleByCountry(window.location.pathname, BirthPlace.UNKNOWN)) }}>
                                    {languages.unknown}
                              </FatButton>
                          </div>
                        )
                    }
                  </div>
                </GenericModal>
              </div>
            )
          }
          {currentInput.default && (
            <Default
              default={currentInput.default}
              checked={currentValue === currentInput.default.value}
              onClick={(defaultValue) => {
                formRef.setFieldsValue({ [currentInput.name]: defaultValue })
                setCurrentValue(defaultValue)
                if (currentInput.name === "nir") {
                  if (defaultValue !== undefined) setDisableNextButton(false)
                  else setDisableNextButton(true)
                }
                if (defaultValue?.length) handleNext()
              }}
            />
          )}
          {/* If the current input is the last one, we display the button[type=submit] */}
          {!myInputs[current + 1] || myInputs[current + 1].type === "hidden" && !myInputs[current + 2] ? (
            <Button
              wide="long"
              type="primary"
              htmlType="submit"
              disabled={loading || disableNextButton}
              loading={loading || loadingNextButton}
            >
              {acceptText || languages.finish}
            </Button>
          ) : (
            <Button
              wide="long"
              type="primary"
              key={current}
              disabled={disableNextButton}
              onClick={handleNext}
            >
              {languages.next}
            </Button>
          )}
        </Buttons>
      </Form>
      <NirBelongsToChildModal
        visible={modalNirBelongsToChild}
        onCancel={() => {
          setModalNirBelongsToChild(false)
        }}
        onAccept={() => {
          formRef.resetFields(["nir"])
          checkChangedValue()
          setModalNirBelongsToChild(false)
        }}
      />
      {hidePrevious && isFirstInput ? null : (
        <div>
          <Steps array={myInputs} current={current} />
          <PreviousButton
            text={
              isFirstInput
                ? cancelText || languages.previous
                : languages.previous
            }
            onClick={handlePrev}
          />
        </div>
      )}
    </div>
  )
}
